import React from "react";
import Particles from "react-particles-js";
import "./Home.css";
export default function Home() {
  const particlesOptions = {
    particles: {
      number: {
        value: 150,
        density: {
          enable: false,
        },
      },
      size: {
        value: 6,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out",
      },
    },
    interactivity: {
      events: {
        onresize: {
          enable: true,
          density_auto: true,
          density_area: 1000, // nb_particles = particles.nb * (canvas width *  canvas height / 1000) / density_area
        },
        onhover: {
          enable: true,
          mode: "repulse",
        },
        onclick: {
          enable: true,
          mode: "bubble",
        },
      },
      modes: {
        bubble: {
          distance: 150,
          duration: 2,
          size: 0,
          opacity: 0,
        },
        repulse: {
          distance: 100,
          duration: 2,
        },
      },
    },
  };

  return (
    <div>
      {/*
				Card - Started
			*/}
      <div className="card-inner card-started active" id="home-card">
        <Particles className="particles" params={particlesOptions} />
        {/* Started Background */}
        <div
          className="slide"
          style={{ backgroundImage: "url(images/bg.jpg)" }}
        />

        <div className="centrize full-width">
          <div className="vertical-center">
            {/* Started titles */}
            <div className="title">
              <span>Waleed</span> Shahzad
            </div>
            <div className="subtitle">
              I am<span> </span>
              <div className="typing-title">
                <p>
                  a <strong>Web Developer.</strong>
                </p>
                <p>
                  an <strong>Android Developer.</strong>
                </p>
                <p>
                  a <strong>Designer.</strong>
                </p>
                <p>
                  a <strong>Freelancer.</strong>
                </p>
              </div>
              <span className="typed-title" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
