import React, { Component } from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

export default class Contact extends Component {
  state = {
    fullName: "",
    email: "",
    message: "",
    submitted:false
  };
  formSubmit = (event) => {
    event.preventDefault();
    const data = {};

    for (let i = 0; i < event.target.length - 1; i++) {
      data[event.target[i].name] = event.target[i].value;
    }
    axios
      .post(`/details`, data)
      .then(() => {
        this.setState({
          submitted : true
        });
        if(this.state.submitted){
          store.addNotification({
            title: "Thanks!",
            message: "I will contact you a ASAP!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 4000,
              onScreen: true,
            },
          });
        }
      })
      .catch((err) => console.log(err));
    event.target.reset();
  };


  render() {
    return (
      <div>
        {/* 
				Card - Contacts
			*/}
        <ReactNotification />
        <div className="card-inner" id="contacts-card">
          <div className="row card-container">
            {/*
						Card Wrap
					*/}
            <div
              className="card-wrap col col-m-12 col-t-12 col-d-8 col-d-lg-6"
              data-simplebar
            >
              {/* Map */}
              <div className="card-image col col-m-12 col-t-12 col-d-4 col-d-lg-6">
                <div style={{ opacity: "0.8" }} className="map" id="map">
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13605.195554223088!2d74.3410888!3d31.5159499!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8cbd6ce309877ba4!2sI.T.%20Tower!5e0!3m2!1sen!2s!4v1579094086003!5m2!1sen!2s"
                    width={"100%"}
                    height={1000}
                    frameBorder={0}
                    style={{ border: 0 }}
                    allowFullScreen
                  />
                </div>
              </div>
              {/*
							Inner Top
						*/}
              <div className="content inner-top">
                <div className="row">
                  <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                    <div className="title-bg">Contacts</div>
                  </div>
                </div>
              </div>
              {/*
							Contacts Info
						*/}
              <div className="content contacts-info">
                <div className="row">
                  <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                    {/* title */}
                    <div className="title">
                      <span>Get</span> in Touch
                    </div>
                  </div>
                </div>
                {/* contacts items */}
                <div className="row contacts-items">
                  {/* contacts item */}
                  <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                    <div className="contacts-item card-box">
                      <div className="icon">
                        <i className="la la-map-marker" />
                      </div>
                      <div className="name">Address</div>
                      <p>IT Tower Lahore, Pakistan.</p>
                    </div>
                  </div>
                  {/* contacts item */}
                  <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                    <div className="contacts-item card-box">
                      <div className="icon">
                        <i className="la la-at" />
                      </div>
                      <div className="name">Email</div>
                      <p>
                        waleedshahzad35 <br />
                        @gmail.com
                      </p>
                    </div>
                  </div>
                  {/* contacts item */}
                  <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                    <div className="contacts-item card-box">
                      <div className="icon">
                        <i className="la la-phone" />
                      </div>
                      <div className="name">Phone</div>
                      <p>+92322 4991944</p>
                    </div>
                  </div>
                  {/* contacts item */}
                  <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                    <div className="contacts-item card-box">
                      <div className="icon">
                        <i className="la la-check-square" />
                      </div>
                      <div className="name">Freelance</div>
                      <p>Available</p>
                    </div>
                  </div>
                </div>
              </div>
              {/*
							Contacts Form
						*/}
              <div className="content contacts-form">
                <div className="row">
                  <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                    {/* title */}
                    <div className="title">
                      <span>Contact</span> Form
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                    {/* form */}
                    <div className="contact_form card-box">
                      <form
                        // method="POST"
                        // action="/details"
                        onSubmit={this.formSubmit}
                      >
                        <div className="row">
                          <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                            <div className="group-val">
                              <input
                                type="text"
                                name="fullName"
                                placeholder="Full Name"
                                required
                              />
                            </div>
                          </div>
                          <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                            <div className="group-val">
                              <input
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                required
                              />
                            </div>
                          </div>
                          <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                            <div className="group-val">
                              <textarea
                                name="message"
                                placeholder="Your Message"
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="align-left">
                          <button
                            type="submit"
                            className="button sm submit_button"
                          >
                            <span className="text">
                              Send Message
                            </span>
                            <span className="icon">
                              <i className="la la-arrow-right" />
                            </span>
                          </button>
                        </div>
                      </form>
                      {/* <div className="alert-success">
                        <p>Thanks, your message sent successfully.</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
