import React from 'react';
// import { Link } from 'react-router-dom';


export default function Navbar() {
    return (
        <div>
            {/*
			Header
		*/}
            <header className="header">
                {/* logo */}
                <div className="logo">
                    <a href="/#"><span>W</span></a>
                </div>
                {/* menu */}
                <div className="top-menu">
                    <ul>
                        <li className="active">
                            {/* <Link to='/home'>
                                <span className="icon la la-home" />
                                <span className="link">Home</span>
                            </Link> */}
                                <a href="#home-card">
                                    <span className="icon la la-home" />
                                    <span className="link">Home</span>
                                </a>
                        </li>
                        <li>
                            {/* <Link to='/about'>
                                <span className="icon la la-user" />
                                <span className="link">About</span>
                        </Link> */}
                        
                            <a href="#about-card">
                                <span className="icon la la-user" />
                                <span className="link">About</span>
                            </a>
                        </li>
                        <li>
                            <a href="#resume-card">
                                <span className="icon"><i className="la la-graduation-cap" /></span>
                                <span className="link">Resume</span>
                            </a>
                        </li>
                        <li>
                            <a href="#works-card">
                                <span className="icon"><i className="las la-photo-video" /></span>
                                <span className="link">Portfolio</span>
                            </a>
                        </li>
                        <li>
                            <a href="#contacts-card">
                                <span className="icon la la-envelope" />
                                <span className="link">Contacts</span>
                            </a>
                        </li>
                    </ul>
                </div>
                {/* Started socials */}
                <div className="social">
                    <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/waleed-shahzad"><span className="icon lab la-linkedin-in" /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/waleed.shahzad.545"><span className="icon la la-facebook" /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/"><span className="icon la la-github" /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://stackoverflow.com/"><span className="icon la la-stack-overflow" /></a>
                </div>
                {/* Mobile Menu */}
                <span className="menu-btn">
                    <span className="m-line" />
                </span>
            </header>

        </div>
    )
}
