import React from 'react';
import Navbar from './navbar/Navbar';
import Home from './home/Home';
import About from './about/About';
import Portfolio from './portfolio/Portfolio';
import Resume from './resume/Resume';
import Contact from './contact/Contact';




function App() {
  return (
      <React.Fragment>
        <Navbar />
        <Home />
        <About />
        <Resume />
        <Portfolio />
        <Contact /> 
      </React.Fragment>
  );
}

export default App;
