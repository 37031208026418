import React from "react";

export default function Resume() {
  return (
    <div>
      {/* 
				Card - Resume
			*/}
      <div className="card-inner" id="resume-card">
        <div className="row card-container">
          {/*
						Card Wrap
					*/}
          <div
            className="card-wrap col col-m-12 col-t-12 col-d-8 col-d-lg-6"
            data-simplebar
          >
            {/* Resume Image */}
            <div
              className="card-image col col-m-12 col-t-12 col-d-4 col-d-lg-6"
              style={{
                backgroundImage: "url(images/profile2.jpg)",
                backgroundSize: "100% 100%",
              }}
            />
            {/*
							Inner Top
						*/}
            <div className="content inner-top">
              <div className="row">
                <div className=" col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                  <div className="title-bg">Resume</div>
                </div>
              </div>
            </div>
            {/*
							Resume
						*/}
            <div className="content resume">
              <div className="row">
                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                  {/* title */}
                  <div className="title">
                    <span>My</span> Experience
                  </div>
                  {/* resume items */}
                  <div className="resume-items card-box">
                    {/* resume item */}
                    <div className="resume-item">
                      <div className="name">MERN Stack Developer</div>
                      <div className="date" style={{ color: "#5ac24e" }}>
                        2020-Present <span>|</span> Hashcrafts
                      </div>
                      <p>
                        Multi-tasked across multiple focuses to generate project
                        results and meet deadlines and organizational
                        expectations. Developed full-stack web applications
                        which processed, analyzed, and rendered data visually.
                      </p>
                    </div>
                    {/* resume item */}

                    <div className="resume-item">
                      <div className="name">Full Stack Developer</div>
                      <div className="date">
                        2019-2020 <span>|</span> OneClout
                      </div>
                      <p>
                        Adjusted design parameters to boost performance and
                        incorporate new features. Conducted testing and review
                        of website design for responsiveness, clarity and
                        eﬀectiveness.
                      </p>
                    </div>
                    {/* resume item */}

                    <div className="resume-item">
                      <div className="name">Front-End Developer</div>
                      <div className="date">
                        2018-2019 <span>|</span> Vvork Cloud Technologies
                      </div>
                      <p>
                        Designed, developed and implemented software
                        applications for website based on analyzed requirements
                        and understanding of industry technical standards
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                  {/* title */}
                  <div className="title">
                    <span>My</span> Education
                  </div>
                  {/* resume items */}
                  <div className="resume-items card-box">
                    {/* resume item */}
                    <div className="resume-item">
                      <div className="name">Virtual University</div>
                      <div className="date">
                        2016-2020 <span>|</span> Lahore Pakistan
                      </div>
                      <p>
                        Bachelor's Degree in Computer Science Virtual
                        University, Lahore, Pakistan
                      </p>
                    </div>
                     {/* resume item */}
                     <div className="resume-item">
                      <div className="name">
                        Certified in building React Application 
                      </div>
                      <div className="date">
                        2020 <span>|</span> PluralSight IQ.
                      </div>
                      <p>Building Web Applications With Advance React and Redux using node as backend</p>
                    </div>
                    {/* resume item */}
                    <div className="resume-item">
                      <div className="name">
                        Microsoft Certified Solution Associate
                      </div>
                      <div className="date">
                        2019 <span>|</span> Microsoft Inc.
                      </div>
                      <p>Certified from Microsoft in Web Applications</p>
                    </div>
                    
                    {/* resume item */}
                    <div className="resume-item">
                      <div className="name">React Bootcamp</div>
                      <div className="date">
                        2019 <span>|</span> Punjab University
                      </div>
                      <p>
                        Conduted Professional MERN Based Bootcamp from Punjab
                        University of Pakistan.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*
							Skills
						*/}
            <div className="content skills">
              <div className="row" data-aos="fade-right">
                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                  {/* title */}
                  <div className="title">
                    <span>Personal</span> Skills
                  </div>
                  {/* skills */}
                  <div className="skills-list card-box">
                    <ul>
                      <li>
                        <div className="name">Communication</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "95%" }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="name">Team Work</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "85%" }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="name">Leadership</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "90%" }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="name">Flexibility</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "83%" }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="name">Creativity</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "77%" }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="name">Time Management</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "95%" }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                  {/* title */}
                  <div className="title">
                    <span>Professional</span> Skills
                  </div>
                  {/* skills */}
                  <div className="skills-list card-box">
                    <ul>
                      <li>
                        <div className="name">MERN</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "90%" }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="name"> HTML / CSS / JS</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "95%" }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="name">React Native</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "75%" }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="name">Ant Design</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "80%" }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="name">Bootstrap</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "85%" }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="name">Photoshop/Invision</div>
                        <div className="progress">
                          <div
                            className="percentage"
                            style={{ width: "70%" }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
