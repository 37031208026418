import React from 'react';

export default function Portfolio() {
    return (
        <div>
            {/* 
				Card - Works
			*/}
            <div className="card-inner" id="works-card">
                <div className="row card-container">
                    {/*
						Card Wrap
					*/}
                    <div className="card-wrap col col-m-12 col-t-12 col-d-8 col-d-lg-6" data-simplebar>
                        {/* Work Image */}
                        <div className="card-image col col-m-12 col-t-12 col-d-4 col-d-lg-6" style={{ backgroundImage: 'url(images/profile3.jpg)',backgroundPosition:' 90% 10%'}} />
                        {/*
							Inner Top
						*/}
                        <div className="content inner-top">
                            <div className="row">
                                <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                                    <div className="title-bg">Portfolio</div>
                                </div>
                            </div>
                        </div>
                        {/*
							Works
						*/}
                        <div className="content works">
                            <div className="row">
                                <div className="col col-m-12 col-t-5 col-d-5 col-d-lg-5">
                                    {/* title */}
                                    <div className="title"><span>My</span> Portfolio</div>
                                </div>
                                <div className="col col-m-12 col-t-7 col-d-7 col-d-lg-7">
                                    {/* filters */}
                                    <div className="filter-menu filter-button-group">
                                        <div className="f_btn active">
                                            <label><input type="radio" name="fl_radio" defaultValue="grid-item" />All</label>
                                        </div>
                                        <div className="f_btn">
                                            <label><input type="radio" name="fl_radio" defaultValue="photo" />Design</label>
                                        </div>
                                        {/* <div className="f_btn">
                                            <label><input type="radio" name="fl_radio" defaultValue="video" />Mobile</label>
                                        </div> */}
                                        <div className="f_btn">
                                            <label><input type="radio" name="fl_radio" defaultValue="design" />Web</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* work items */}
                            <div className="row grid-items">
                                {/* work item photo */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item photo">
                                    <div className="box-item card-box">
                                        <div className="image">
                                            <a href="images/works/work2.png" className="has-popup-image">
                                                <img src="images/works/work2.png" alt="" />
                                                <span className="info">
                                                    <span className="icon la la-image" />

                                                </span>
                                            </a>
                                        </div>
                                        <div className="desc">
                                            <a href="images/works/work2.png" className="name has-popup-image">Slick Template Engine</a>
                                            <div className="category">Design</div>
                                        </div>
                                    </div>
                                </div>


                                {/* Web Div  */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item design">
                                    <div className="box-item card-box">
                                        <div className="image">
                                            <a href="#popup-1" className="has-popup-media">
                                                <img src="images/works/work1.png" alt="" />
                                                <span className="info">
                                                    <span className="icon la la-camera-retro" />

                                                </span>
                                            </a>
                                        </div>
                                        <div className="desc">
                                            <a href="#popup-1" className="name has-popup-media">Web Application</a>
                                            <div className="category">Web</div>
                                        </div>
                                        <div id="popup-1" className="popup-box mfp-fade mfp-hide">
                                            <div className="content">
                                                <div className="image">
                                                    <img src="images/works/work1.png" alt="" />
                                                </div>
                                                <div className="desc">
                                                    <div className="post-box">
                                                        <h1>VVORK Cloud Technologies</h1>
                                                        <div className="blog-detail">Web</div>
                                                        <div className="blog-content">
                                                            <p>
                                                                This application manage the student profiles and courses as well.
                                                                 Admin can add user/student and then that user can login,
                                                                 update his bio add projects to his profile, edit his projects,
                                                                  add edit update delete his skills in his tech stack.
                                                            </p>

                                                            <p>
                                                                User can browse students according to required skill and then can request to hire
                                                                that student as well as assign them freelance projects.
                                                            </p>

                                                            <ul className="list-style">
                                                                <li>On the other hand admin can manage, delete, enable or disable users</li>
                                                                <li>Guest user can request to join the certain events and can also enroll in the course </li>
                                                                <li>And admin can also download that data to excel if needed</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Web Div  */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item design">
                                    <div className="box-item card-box">
                                        <div className="image">
                                            <a href="#popup-3" className="has-popup-media">
                                                <img src="images/works/work5.png" alt="" />
                                                <span className="info">
                                                    <span className="icon la la-camera-retro" />
                                                </span>
                                            </a>
                                        </div>
                                        <div className="desc">
                                            <a href="#popup-3" className="name has-popup-media">Dashboard Application</a>
                                            <div className="category">Web</div>
                                        </div>
                                        <div id="popup-3" className="popup-box mfp-fade mfp-hide">
                                            <div className="content">
                                                <div className="image">
                                                    <img src="images/works/work5.png" alt="" />
                                                </div>
                                                <div className="desc">
                                                    <div className="post-box">
                                                        <h1>Dashboard Application</h1>
                                                        <div className="blog-detail">Web</div>
                                                        <div className="blog-content">
                                                            <p>
                                                            Light Blue React Template is attractive and efficient transparent dashboard built with 
                                                            Bootstrap 4 and React. It uses Server Side Rendering 
                                                            for SEO optimization and Node.js backend to even
                                                             further speed up your development. Light Blue
                                                              React admin template can be used to create
                                                               analytics dashboards
                                                            </p>

                                                            
                                                           <p>
                                                           Material Dashboard React makes use of light, 
                                                           surface and movement. The general layout
                                                            resembles sheets of paper following 
                                                            multiple different layers, so that
                                                             the depth and order is obvious. 
                                                             The navigation stays mainly
                                                              on the left sidebar and
                                                               the content is on 
                                                               the right inside
                                                                the main panel.
                                                           </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* work item photo */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item photo">
                                    <div className="box-item card-box">
                                        <div className="image">
                                            <a href="images/works/work3.png" className="has-popup-image">
                                                <img src="images/works/work3.png" alt="" />
                                                <span className="info">
                                                    <span className="icon la la-image" />

                                                </span>
                                            </a>
                                        </div>
                                        <div className="desc">
                                            <a href="images/works/work3.png" className="name has-popup-image">Logic Theme Master
                                            </a>
                                            <div className="category">Design</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Web Div  */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item design">
                                    <div className="box-item card-box">
                                        <div className="image">
                                            <a href="#popup-5" className="has-popup-media">
                                                <img src="images/works/work6.png" alt="" />
                                                <span className="info">
                                                    <span className="icon la la-camera-retro" />

                                                </span>
                                            </a>
                                        </div>
                                        <div className="desc">
                                            <a href="#popup-5" className="name has-popup-media">Web Application</a>
                                            <div className="category">Web</div>
                                        </div>
                                        <div id="popup-5" className="popup-box mfp-fade mfp-hide">
                                            <div className="content">
                                                <div className="image">
                                                    <img src="images/works/work6.png" alt="" />
                                                </div>
                                                <div className="desc">
                                                    <div className="post-box">
                                                        <h1>Vonza</h1>
                                                        <div className="blog-detail">Web</div>
                                                        <div className="blog-content">
                                                            <p>
                                                            Vonza is the ultimate platform to build your online courses,
                                                             sell products, construct sales funnels and develop amazing websites.
                                                            </p>

                                                            <p>
                                                            Within minutes, you will get a fully functioning online website 
                                                            platform with the latest technology, full learning management,
                                                             payment processing, sales and marketing tools to grow your
                                                              business under your own brand and custom domain.
                                                            </p>

                                                            <p>
                                                            Create engaging courses. Add video, image, text, audio and PDF files. Easily import your content from other platforms.
                                                            Use your own domain name. Build and design a beautiful website and membership pages with blog. Hosting Included.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* work item design */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item design">
                                    <div className="box-item card-box">
                                        <div className="image">
                                            <a href="#popup-4" className="has-popup-media">
                                                <img src="images/works/work4.png" alt="" />
                                                <span className="info">
                                                    <span className="icon la la-camera-retro" />

                                                </span>
                                            </a>
                                        </div>
                                        <div className="desc">
                                            <a href="#popup-4" className="name has-popup-media">Edugate Academy</a>
                                            <div className="category">Web</div>
                                        </div>
                                        <div id="popup-4" className="popup-box mfp-fade mfp-hide">
                                            <div className="content">
                                                <div className="image">
                                                    <img src="images/works/work4.png" alt="" />
                                                </div>
                                                <div className="desc">
                                                    <div className="post-box">
                                                        <h1>Academy Application</h1>
                                                        <div className="blog-detail">Web</div>
                                                        <div className="blog-content">
                                                            <p>
                                                                Education is a great start for an education personnel or organization
                                                                to start the online business. All pages are fully layered and
                                                                simply customizable, all elements are in groups and can
                                                                easily identify by the group name as well.

                                                            </p>

                                                            <blockquote>
                                                                Edugate is a Modern, Creative, Responsive & Multipurpose
                                                                Website suitable for Educational
                                                                Institutions
                                                            </blockquote>
                                                            <p>
                                                                It can be used for a school, library, training center or even a private instructors
                                                                Admin can manage students profile delete and edit other users.
                                                            </p>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* work item photo */}
                                {/* <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item photo">
                                    <div className="box-item card-box">
                                        <div className="image">
                                            <a href="images/works/work10.png" className="has-popup-image">
                                                <img src="images/works/work10.png" alt="" />
                                                <span className="info">
                                                    <span className="icon la la-image" />

                                                </span>
                                            </a>
                                        </div>
                                        <div className="desc">
                                            <a href="images/works/work10.png" className="name has-popup-image">Arki Nature
                                            </a>
                                            <div className="category">Design</div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


























// import React from "react";

// export default function Portfolio() {
//   return (
//     <div>
//       {/* 
// 				Card - Works
// 			*/}
//       <div className="card-inner" id="works-card">
//         <div className="row card-container">
//           {/*
// 						Card Wrap
// 					*/}
//           <div
//             className="card-wrap col col-m-12 col-t-12 col-d-8 col-d-lg-6"
//             data-simplebar
//           >
//             {/* Work Image */}
//             <div
//               className="card-image col col-m-12 col-t-12 col-d-4 col-d-lg-6"
//               style={{
//                 backgroundImage: "url(images/profile3.jpg)",
//                 backgroundPosition: " 90% 10%",
//               }}
//             />
//             {/*
// 							Inner Top
// 						*/}
//             <div className="content inner-top">
//               <div className="row">
//                 <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
//                   <div className="title-bg">Portfolio</div>
//                 </div>
//               </div>
//             </div>
//             {/*
// 							Works
// 						*/}
//             <div className="content works">
//               <div className="row">
//                 <div className="col col-m-12 col-t-5 col-d-5 col-d-lg-5">
//                   {/* title */}
//                   <div className="title">
//                     <span>My</span> Portfolio
//                   </div>
//                 </div>
//                 <div className="col col-m-12 col-t-7 col-d-7 col-d-lg-7">
//                   {/* filters */}
//                   <div className="filter-menu filter-button-group">
//                     <div className="f_btn active">
//                       <label>
//                         <input
//                           type="radio"
//                           name="fl_radio"
//                           defaultValue="grid-item"
//                         />
//                         All
//                       </label>
//                     </div>
//                     <div className="f_btn">
//                       <label>
//                         <input
//                           type="radio"
//                           name="fl_radio"
//                           defaultValue="photo"
//                         />
//                         Design
//                       </label>
//                     </div>
//                     <div className="f_btn">
//                       <label>
//                         <input type="radio" name="fl_radio" value="video" />
//                         Mobile
//                       </label>
//                     </div>
//                     <div className="f_btn">
//                       <label>
//                         <input
//                           type="radio"
//                           name="fl_radio"
//                           defaultValue="design"
//                         />
//                         Web
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               {/* work items */}
//               <div className="row grid-items">
//                 {/* work item photo */}
//                 <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item photo">
//                   <div className="box-item card-box">
//                     <div className="image">
//                       <a
//                         href="images/works/work2.png"
//                         className="has-popup-image"
//                       >
//                         <img src="images/works/work2.png" alt="" />
//                         <span className="info">
//                           <span className="icon la la-image" />
//                         </span>
//                       </a>
//                     </div>
//                     <div className="desc">
//                       <a
//                         href="images/works/work2.png"
//                         className="name has-popup-image"
//                       >
//                         Slick Template Engine
//                       </a>
//                       <div className="category">Design</div>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Mobile App  */}

//                 <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item design">
//                   <div className="box-item card-box">
//                     <div className="image">
//                       <a href="#popup-4" className="has-popup-media">
//                         <img src="images/works/work12 - Copy.jpeg" alt="true" />
//                         <span className="info">
//                           <span className="icon la la-image" />
//                         </span>
//                       </a>
//                     </div>
//                     <div className="desc">
//                       <a href="#popup-4" className="name has-popup-media">
//                         Saffron Restaurant
//                       </a>
//                       <div className="category">Mobile App</div>
//                     </div>
//                     <div id="popup-4" className="popup-box mfp-fade mfp-hide">
//                       <div className="content">
//                         <div className="image">
//                           <img src="images/works/work12_Photoshoped.jpg" alt="Image" />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 {/* work item video */}
//                 <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item video">
//                   <div className="box-item card-box">
//                     <div className="image">
//                       <a
//                         className="has-popup-video"
//                       >
//                         <img src="images/works/work13 - Copy.jpeg" alt="" />
//                         <span className="info">
//                           <span className="icon la la-camera-retro" />
//                         </span>
//                       </a>
//                     </div>
//                     <div className="desc">
//                       <a
//                         className="name has-popup-video"
//                       >
//                           Saffron Restaurant
//                       </a>
//                       <div className="category">Mobile App</div>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Web Div  */}
//                 <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item design">
//                   <div className="box-item card-box">
//                     <div className="image">
//                       <a href="#popup-1" className="has-popup-media">
//                         <img src="images/works/work1.png" alt="" />
//                         <span className="info">
//                           <span className="icon la la-camera-retro" />
//                         </span>
//                       </a>
//                     </div>
//                     <div className="desc">
//                       <a href="#popup-1" className="name has-popup-media">
//                         Web Application
//                       </a>
//                       <div className="category">Web</div>
//                     </div>
//                     <div id="popup-1" className="popup-box mfp-fade mfp-hide">
//                       <div className="content">
//                         <div className="image">
//                           <img src="images/works/work1.png" alt="" />
//                         </div>
//                         <div className="desc">
//                           <div className="post-box">
//                             <h1>VVORK Cloud Technologies</h1>
//                             <div className="blog-detail">Web</div>
//                             <div className="blog-content">
//                               <p>
//                                 This application manage the student profiles and
//                                 courses as well. Admin can add user/student and
//                                 then that user can login, update his bio add
//                                 projects to his profile, edit his projects, add
//                                 edit update delete his skills in his tech stack.
//                               </p>

//                               <p>
//                                 User can browse students according to required
//                                 skill and then can request to hire that student
//                                 as well as assign them freelance projects.
//                               </p>

//                               <ul className="list-style">
//                                 <li>
//                                   On the other hand admin can manage, delete,
//                                   enable or disable users
//                                 </li>
//                                 <li>
//                                   Guest user can request to join the certain
//                                   events and can also enroll in the course{" "}
//                                 </li>
//                                 <li>
//                                   And admin can also download that data to excel
//                                   if needed
//                                 </li>
//                               </ul>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 {/* Web Div  */}
//                 <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item design">
//                   <div className="box-item card-box">
//                     <div className="image">
//                       <a href="#popup-3" className="has-popup-media">
//                         <img src="images/works/work5.png" alt="" />
//                         <span className="info">
//                           <span className="icon la la-camera-retro" />
//                         </span>
//                       </a>
//                     </div>
//                     <div className="desc">
//                       <a href="#popup-3" className="name has-popup-media">
//                         Dashboard Application
//                       </a>
//                       <div className="category">Web</div>
//                     </div>
//                     <div id="popup-3" className="popup-box mfp-fade mfp-hide">
//                       <div className="content">
//                         <div className="image">
//                           <img src="images/works/work5.png" alt="" />
//                         </div>
//                         <div className="desc">
//                           <div className="post-box">
//                             <h1>Dashboard Application</h1>
//                             <div className="blog-detail">Web</div>
//                             <div className="blog-content">
//                               <p>
//                                 Light Blue React Dashboard is attractive and
//                                 efficient transparent dashboard built with
//                                 Bootstrap 4 and React. It uses Server Side
//                                 Rendering for SEO optimization and Node.js
//                                 backend to even further speed up your
//                                 development. Light Blue React admin template can
//                                 be used to create analytics dashboards
//                               </p>

//                               <p>
//                                 Material Dashboard React makes use of light,
//                                 surface and movement. The general layout
//                                 resembles sheets of paper following multiple
//                                 different layers, so that the depth and order is
//                                 obvious. The navigation stays mainly on the left
//                                 sidebar and the content is on the right inside
//                                 the main panel.
//                               </p>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 {/* work item photo */}
//                 <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item photo">
//                   <div className="box-item card-box">
//                     <div className="image">
//                       <a
//                         href="images/works/work3.png"
//                         className="has-popup-image"
//                       >
//                         <img src="images/works/work3.png" alt="" />
//                         <span className="info">
//                           <span className="icon la la-image" />
//                         </span>
//                       </a>
//                     </div>
//                     <div className="desc">
//                       <a
//                         href="images/works/work3.png"
//                         className="name has-popup-image"
//                       >
//                         Logic Theme Master
//                       </a>
//                       <div className="category">Design</div>
//                     </div>
//                   </div>
//                 </div>
//                 {/* work item photo */}
//                 <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item photo">
//                   <div className="box-item card-box">
//                     <div className="image">
//                       <a
//                         href="images/works/work11.jpeg"
//                         className="has-popup-image"
//                       >
//                         <img src="images/works/work11 - Copy.jpeg" alt="" />
//                         <span className="info">
//                           <span className="icon la la-image" />
//                         </span>
//                       </a>
//                     </div>
//                     <div className="desc">
//                       <a
//                         href="images/works/work11.jpeg"
//                         className="name has-popup-image"
//                       >
//                         Beedoc (Personal Medical Assistant)
//                       </a>
//                       <div className="category">Mobile App</div>
//                     </div>
//                   </div>
//                 </div>
//                 {/* Web Div  */}
//                 <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item design">
//                   <div className="box-item card-box">
//                     <div className="image">
//                       <a href="#popup-5" className="has-popup-media">
//                         <img src="images/works/work6.png" alt="" />
//                         <span className="info">
//                           <span className="icon la la-camera-retro" />
//                         </span>
//                       </a>
//                     </div>
//                     <div className="desc">
//                       <a href="#popup-5" className="name has-popup-media">
//                         Web Application
//                       </a>
//                       <div className="category">Web</div>
//                     </div>
//                     <div id="popup-5" className="popup-box mfp-fade mfp-hide">
//                       <div className="content">
//                         <div className="image">
//                           <img src="images/works/work6.png" alt="" />
//                         </div>
//                         <div className="desc">
//                           <div className="post-box">
//                             <h1>Vonza</h1>
//                             <div className="blog-detail">Web</div>
//                             <div className="blog-content">
//                               <p>
//                                 Vonza is the ultimate platform to build your
//                                 online courses, sell products, construct sales
//                                 funnels and develop amazing websites.
//                               </p>

//                               <p>
//                                 Within minutes, you will get a fully functioning
//                                 online website platform with the latest
//                                 technology, full learning management, payment
//                                 processing, sales and marketing tools to grow
//                                 your business under your own brand and custom
//                                 domain.
//                               </p>

//                               <p>
//                                 Create engaging courses. Add video, image, text,
//                                 audio and PDF files. Easily import your content
//                                 from other platforms. Use your own domain name.
//                                 Build and design a beautiful website and
//                                 membership pages with blog. Hosting Included.
//                               </p>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 {/* work item design */}
//                 <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item design">
//                   <div className="box-item card-box">
//                     <div className="image">
//                       <a href="#popup-4" className="has-popup-media">
//                         <img src="images/works/work4.png" alt="" />
//                         <span className="info">
//                           <span className="icon la la-camera-retro" />
//                         </span>
//                       </a>
//                     </div>
//                     <div className="desc">
//                       <a href="#popup-4" className="name has-popup-media">
//                         Edugate Academy
//                       </a>
//                       <div className="category">Web</div>
//                     </div>
//                     <div id="popup-4" className="popup-box mfp-fade mfp-hide">
//                       <div className="content">
//                         <div className="image">
//                           <img src="images/works/work4.png" alt="" />
//                         </div>
//                         <div className="desc">
//                           <div className="post-box">
//                             <h1>Academy Application</h1>
//                             <div className="blog-detail">Web</div>
//                             <div className="blog-content">
//                               <p>
//                                 Education is a great start for an education
//                                 personnel or organization to start the online
//                                 business. All pages are fully layered and simply
//                                 customizable, all elements are in groups and can
//                                 easily identify by the group name as well.
//                               </p>

//                               <blockquote>
//                                 Edugate is a Modern, Creative, Responsive &
//                                 Multipurpose Website suitable for Educational
//                                 Institutions
//                               </blockquote>
//                               <p>
//                                 It can be used for a school, library, training
//                                 center or even a private instructors Admin can
//                                 manage students profile delete and edit other
//                                 users.
//                               </p>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 {/* work item photo */}
//                 {/* <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 grid-item photo">
//                                     <div className="box-item card-box">
//                                         <div className="image">
//                                             <a href="images/works/work10.png" className="has-popup-image">
//                                                 <img src="images/works/work10.png" alt="" />
//                                                 <span className="info">
//                                                     <span className="icon la la-image" />

//                                                 </span>
//                                             </a>
//                                         </div>
//                                         <div className="desc">
//                                             <a href="images/works/work10.png" className="name has-popup-image">Arki Nature
//                                             </a>
//                                             <div className="category">Design</div>
//                                         </div>
//                                     </div>
//                                 </div> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
