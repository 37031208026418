import React from 'react';

export default function About() {
    return (
        <div>

            {/* 
				Card - About
			*/}
            <div className="card-inner" id="about-card">
                <div className="row card-container">
                    {/*
						Card Wrap
					*/}
                    <div className="card-wrap col col-m-12 col-t-12 col-d-8 col-d-lg-6" data-simplebar>
                        {/* About Image */}
                        <div className="card-image col col-m-12 col-t-12 col-d-4 col-d-lg-6" style={{backgroundImage: 'url(images/profile0.jpg)',backgroundPosition:' 20% 10%' }} />
                        {/*
							Inner Top
						*/}
                        <div className="content inner-top">
                            <div className="row">
                                <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                                    <div className="title-bg">About Me</div>
                                    <div className="text">
                                        <p>
                                            <strong>Hello, I’m Waleed</strong>, Web-Developer based in Pakistan. <br />
                                            I have Rich experience in website Development<br /> &amp; Designing
                                             . Also I am good at <strong>React, Node<br />
                                                Express,MongoDB,JavaScript,Bootstrap.</strong>
                                        </p>
                                    </div>
                                    <div className="circle-bts">
                                        <a href="Resume/Current-Resume.pdf" download="Waleed's Resume"><span><i className="icon la la-download" />Download CV</span></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/waleed.shahzad.545"><i className="icon la la-facebook" /></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/waleed-shahzad"><i className="icon lab la-linkedin-in" /></a>
                                        <a target="_blank" rel="noopener noreferrer" href="/#"><i className="icon la la-stack-overflow" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*
							Services
						*/}
                        <div className="content services">
                            <div className="row">
                                <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                                    {/* title */}
                                    <div className="title"><span>My</span> Services</div>
                                </div>
                            </div>
                            {/* service items */}
                            <div className="row service-items">
                                {/* service item */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6 text-center">
                                    <div className="service-item card-box text-center">
                                        <div className="icon"><i className="la la-laptop-code" /></div>
                                        <div className="name">Web Development</div>
                                        <p>
                                        Expert in building fast, secure and scalable web applications that will help your business to grow
              </p>
                                    </div>
                                </div>
                                {/* service item */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                                    <div className="service-item card-box">
                                        <div className="icon"><i className="la la-shopping-cart" /></div>
                                        <div className="name">Ecommerce Solutions</div>
                                        <p>
                                       Feature-packed website that boost your sell and improve online presence of your business.
              </p>
                                    </div>
                                </div>
                                {/* service item */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                                    <div className="service-item card-box">
                                        <div className="icon"><i className="lab la-sketch" /></div>
                                        <div className="name">Graphic Designing</div>
                                        <p>
                                       Enough Experience in unique, creative, eye catching, professional & clean design.
              </p>
                                    </div>
                                </div>
                                {/* service item */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                                    <div className="service-item card-box">
                                        <div className="icon"><i className="las la-icons" /></div>
                                        <div className="name">Psd To Html</div>
                                        <p>
                                            Specialized in creating responsive and pixel perfect website of your PSD file. 
              </p>
                                    </div>
                                </div>
                                {/* service item */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                                    <div className="service-item card-box">
                                        <div className="icon"><i className="las la-paint-brush" /></div>
                                        <div className="name">UI/UX Designer</div>
                                        <p>
                                        Have extensive experience and expert knowledge in building attractive UI & UX.
              </p>
                                    </div>
                                </div>
                                {/* service item */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                                    <div className="service-item card-box">
                                        <div className="icon"><i className="la la-android" /></div>
                                        <div className="name">Mobile App Development</div>
                                        <p>
                                        Have an ample amount of knowledge in Android & iOS app development
              </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*
							Price Tables
						*/}
                        <div className="content pricing">
                            <div className="row">
                                <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                                    {/* title */}
                                    <div className="title"><span>Pricing</span> Plans</div>
                                </div>
                            </div>
                            {/* pricing items */}
                            <div className="row pricing-items">
                                {/* pricing item */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                                    <div className="pricing-item card-box">
                                        <div className="icon"><i className="la la-tachometer" /></div>
                                        <div className="name">Basic</div>
                                        <div className="amount">
                                            <span className="dollar">$</span>
                                            <span className="number">20</span>
                                            <span className="period">hour</span>
                                        </div>
                                        <div className="feature-list">
                                            <ul>
                                                <li>Web Development</li>
                                                <li>Responsive Design</li>
                                                <li>Source Code</li>
                                                <li>Content Upload</li>
                                                <li>3 Revisions</li>
                                                <li className="disable">Customization <strong>new</strong></li>
                                            </ul>
                                        </div>
                                        {/* <div className="lnks">
                                            <a href="/#" className="lnk">Buy <span>Basic</span></a>
                                        </div> */}
                                    </div>
                                </div>
                                {/* pricing item */}
                                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                                    <div className="pricing-item card-box">
                                        <div className="icon"><i className="la la-rocket" /></div>
                                        <div className="name">Pro</div>
                                        <div className="amount">
                                            <span className="dollar">$</span>
                                            <span className="number">40</span>
                                            <span className="period">hour</span>
                                        </div>
                                        <div className="feature-list">
                                            <ul>
                                                <li>Web Development</li>
                                                <li>Responsive Design</li>
                                                <li>Source Code</li>
                                                <li>Content Upload</li>
                                                <li>Unlimited Revisions</li>
                                                <li>Customization <strong>new</strong></li>
                                            </ul>
                                        </div>
                                        {/* <div className="lnks">
                                            <a href="/#" className="lnk">Buy <span>Pro</span></a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*
							Fun Fact
						*/}
                        <div className="content fuct">
                            <div className="row">
                                <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                                    {/* title */}
                                    <div className="title"><span>Fun</span> Facts</div>
                                </div>
                            </div>
                            {/* fuct items */}
                            <div className="row fuct-items">
                                {/* fuct item */}
                                <div className="col col-m-6 col-t-6 col-d-3 col-d-lg-3">
                                    <div className="fuct-item card-box">
                                        <div className="icon"><i className="las la-laugh-wink" /></div>
                                        <div className="name">Snooker Champion <p> </p>
                                        </div>
                                    </div>
                                </div>
                                {/* fuct item */}
                                <div className="col col-m-6 col-t-6 col-d-3 col-d-lg-3">
                                    <div className="fuct-item card-box">
                                        <div className="icon"><i className="la la-lightbulb-o" /></div>
                                        <div className="name">50+ Projects Completed</div>
                                    </div>
                                </div>
                                <div className="col col-m-6 col-t-6 col-d-3 col-d-lg-3">
                                    <div className="fuct-item card-box">
                                        <div className="icon"><i className="la la-code" /></div>
                                        <div className="name">100000+ Lines of Code</div>
                                    </div>
                                </div>
                                {/* fuct item */}
                                <div className="col col-m-6 col-t-6 col-d-3 col-d-lg-3">
                                    <div className="fuct-item card-box">
                                        <div className="icon"><i className="la la-smile-o" /></div>
                                        <div className="name">10+ Satisfied Customers</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*
							Clients
						*/}
                        {/* <div className="content clients">
                            <div className="row">
                                <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                                    <div className="title"><span>My</span> Clients</div>
                                </div>
                            </div>
                            <div className="row client-items"> */}
                                {/* <div className="col col-m-6 col-t-6 col-d-3 col-d-lg-3">
                                    <div className="client-item card-box">
                                        <div className="image">
                                            <a href="#">
                                                <img src="images/clients/logo.png" style={{height:35}} alt="Img" />
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col col-m-6 col-t-6 col-d-3 col-d-lg-3">
                                    <div className="client-item card-box">
                                        <div className="image">
                                            <a href="#">
                                                <img src="images/clients/logo2.png" alt="Img" />
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>

        </div>
    )
}
